<template>
  <v-card
    outlined
    style="padding: 2rem"
  >
    <div class="title">
      <h2>Planning d'occupation des chambre.</h2>
    </div>
    <FullCalendar :options="calendarOptions" />
    <v-dialog
      v-model="dialog"
      max-width="600px"
      transition="dialog-top-transition"
    >
      <template>
        <v-card>
          <v-card-text>
            <div class="pa-2 pt-5">
              <div>
                <span class="big-title mark uppercase">Details du Séjour Numéro&emsp; </span>
                <span class="big-title mark important">{{ stay.id }}</span>
              </div>
              <div>
                <span class="big-title mark uppercase">Montant restant a payer&emsp; </span>
                <span class="big-title important mark">{{ stay.stays_remaining_amount }} FCFA</span>
              </div>
              <div>
                <span class="big-title mark uppercase">Soldé&emsp; </span>
                <span class="big-title important mark uppercase">{{ stay.stays_pay_off ? 'oui' : 'non' }}</span>
              </div>
              <div>
                <span class="big-title mark uppercase">Occupants&emsp; </span>
                <span class="big-title important mark uppercase">{{ stay.stays_number_of_occupants }}</span>
              </div>
              <div>
                <span class="big-title mark uppercase">Reduction&emsp; </span>
                <span class="big-title important mark">{{ stay.stays_reduce ? stay.stays_reduce : 0 }} FCFA</span>
              </div>
              <div>
                <span class="big-title mark uppercase">Enregistré avec le compte&emsp; </span>
                <span class="big-title important mark">{{ stay.user && (stay.user.email) }}</span>
              </div>
              <div>
                <span class="big-title mark uppercase">Statut&emsp; </span>
                <span class="big-title important mark">
                  <v-chip
                    :color="statusColor[status[stay.stays_status]]"
                    class="font-weight-medium"
                    small
                  >
                    {{ status[stay.stays_status] }}
                  </v-chip>
                </span>
              </div>
              <div>
                <span class="big-title mark uppercase">Date d'entrée&emsp; </span>
                <span class="big-title important mark">{{ stay.stays_enter_date }}</span>
              </div>
              <div>
                <span class="big-title mark uppercase">Date de sortis&emsp; </span>
                <span class="big-title important mark">{{ stay.stays_leaving_date }}</span>
              </div>
              <div>
                <span class="big-title mark uppercase">Nombre de Nuitée&emsp; </span>
                <span class="big-title important mark">{{ stay.stays_number_of_days }}</span>
              </div>
              <div>
                <span class="big-title mark uppercase">Payements effectués&emsp; <br></span>
                <div
                  v-for="paiment in stay.stay_payments"
                  :key="paiment.id"
                >
                  <div>
                    <span class="big-title important mark">{{ paiment.pay_stay_date }}</span>&emsp;
                    <span class="big-title important mark">{{ paiment.pay_stay_amount }} FCFA </span>
                  </div>
                </div>
              </div>
              <div>
                <span class="big-title mark uppercase"> Payement total effectuer&emsp; </span>
                <span class="big-title important mark">{{ totalPay(stay.stay_payments) }} FCFA</span>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialog = false"
            >
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-card>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'
import moment from 'moment/moment'

const random = function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}

export default {
  name: 'RoomOccupancySchedule',
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      En_cours: 'primary',
      Cloturé: 'success',
      Annulé: 'warning',
      Resigned: 'warning',
      Applied: 'info',
      /* eslint-enable key-spacing */
    }

    return {
      statusColor,
      status: {
        400: 'En_cours',
        200: 'Cloturé',
        500: 'Annulé',
        550: 'Ecourter',
        5: 'Applied',
      },
    }
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        eventClick: e => {
          this.dialog = true
          this.stay = e.event.extendedProps.stay
          console.log(this.stay)
        },
        locale: frLocale,
        events: [],
      },
      dialog: false,
      stay: {},
    }
  },
  mounted() {
    this.$axios.get(this.$endpoint.LoadStay).then(rp => {
      const events = []

      // console.log(moment(rp.data[0].stays_leaving_date).add(1, 'days').format(''))
      const colors = [
        '#C11E1F',
        '#56CA00',
        '#FFB400',
        '#16B1FF',
      ]
      rp.data.forEach(s => {
        events.push({
          title: s.room.room_name.toUpperCase(),
          start: s.stays_enter_date,
          allDay: !0,
          stay: s,
          color: colors[random(0, 4)],
          end: moment(s.stays_leaving_date).add(1, 'days').format('YYYY-MM-DD'),
        })
      })
      this.calendarOptions.events = events
    })
  },
  methods: {
    handleDateClick(arg) {
      alert(`date click! ${arg.dateStr}`)
    },
    totalPay(payments) {
      let total = 0
      if (payments) {
        // eslint-disable-next-line no-plusplus
        for (let e = 0; e < payments.length; e++) {
          total += payments[e].pay_stay_amount
        }
      }

      return total
    },
  },
}
</script>

<style scoped>

</style>
